// Computes the hype score from -100 to +100 to 0 - 100

export const computeHypeScore = (score: number = 0) => {
  return Math.round((score + 100) / 2);
};

// Reverses the computeHypeScore function to get the original score from the hype score
export const computeOriginalScore = (hypeScore: number = 0) => {
  return 2 * hypeScore - 100;
};
