import { IncomingMessage } from 'http';
import { ApiServiceClass } from '@/services/ApiServiceClass';
import { getBaseURLByRequest } from './url';

export const getAPIByRequest = (req: IncomingMessage) => {
  const auth = req.headers['authorization'];
  const apiServer = new ApiServiceClass({
    BASE: `${getBaseURLByRequest(req)}/api/`,
    HEADERS: !auth ? undefined : { Authorization: auth },
  });

  return {
    apiServer,
    hasAuth: Boolean(auth),
  };
};
