import { Flex } from 'moralis-ui';
import { TTokenStrategiesDataResponsePartial } from '@/utils/contentful/type';
import {
  EmptyBoxOpenFilterLink,
  EmptyBoxSwiperWrapper,
  EmptyBoxTitle,
  EmptyBoxTitleCollection,
  EmptyBoxTokenExplorerLink,
  EmptyBoxWithTokensLink,
  EmptyBoxWrapper,
} from './EmptyBox';
import { IBSwiperItem, IBSwiperWrap } from './IBSwiper';

const INITIAL_SLIDE = 0;
const INITIAL_SLIDES_PER_VIEW = 1;

export const TrendingPageUIEmpty = ({
  onShowFilters,
  chainId,
  category,
  categoryTitle,
  chainTitle,
  contentfulTokenDataStrategies = [],
  tokensCount,
}: {
  onShowFilters: () => void;
  chainId: string | null;
  category: string | null;
  categoryTitle?: string | undefined;
  chainTitle?: string | undefined;
  contentfulTokenDataStrategies?: TTokenStrategiesDataResponsePartial[];
  tokensCount: string | undefined;
}) => {
  return (
    <EmptyBoxWrapper>
      <EmptyBoxTitle categoryTitle={categoryTitle} chainTitle={chainTitle} />
      <EmptyBoxTokenExplorerLink
        chainId={chainId}
        category={category}
        categoryTitle={categoryTitle}
        chainTitle={chainTitle}
        tokensCount={tokensCount}
      />
      <EmptyBoxSwiperWrapper>
        <IBSwiperWrap
          autoHeight={true}
          initialSlide={INITIAL_SLIDE}
          slidesPerView={INITIAL_SLIDES_PER_VIEW}
          id="TrendingPageUIEmpty"
        >
          {contentfulTokenDataStrategies.map(
            (strategy) =>
              strategy.tokenData &&
              strategy.tokenData.length > 0 && (
                <IBSwiperItem key={strategy.collectionName}>
                  <Flex direction="column" align="center" spacing={16}>
                    <EmptyBoxTitleCollection collectionTitle={strategy.collectionName} chainId={chainId} />
                    <Flex
                      direction={{
                        md: 'column',
                        lg: 'row',
                      }}
                      justify="center"
                      spacing={{ lg: 12, xl: 24 }}
                      width="100%"
                    >
                      {strategy.tokenData.map((token) => (
                        <EmptyBoxWithTokensLink
                          key={`${token.metadata.uniqueName}_${token.metadata.coinAddress}_${token.metadata.chainId}_${token.metadata.symbol}`}
                          tokenData={token || []}
                        />
                      ))}
                    </Flex>
                  </Flex>
                </IBSwiperItem>
              ),
          )}
        </IBSwiperWrap>
      </EmptyBoxSwiperWrapper>
      <EmptyBoxOpenFilterLink onShowFilters={onShowFilters} />
    </EmptyBoxWrapper>
  );
};
