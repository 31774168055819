import { useCallback } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { logApp } from '@/utils/logApp';

const log = logApp.create('useDefaultNavigator');

type NavParams = { pathname: string; searchString: string };
const navigate = (nav: (url: string) => void, { pathname, searchString }: NavParams) => {
  log.debug('navigate', { pathname, searchString });
  nav(!searchString ? pathname : [pathname, searchString].join('?'));
};

export const useDefaultNavigator = () => {
  const router = useRouter();
  const currentPathname = usePathname();
  const currentSearch = useSearchParams().toString();

  return {
    router,
    pathname: currentPathname,
    searchString: currentSearch,

    navigate: useCallback((navParams: NavParams) => navigate(router.push, navParams), [router]),
    pushPathname: useCallback(
      (pathname: string) => navigate(router.push, { pathname, searchString: currentSearch }),
      [router, currentSearch],
    ),
    replacePathname: useCallback(
      (pathname: string) => navigate(router.replace, { pathname, searchString: currentSearch }),
      [router, currentSearch],
    ),

    pushSearchString: useCallback(
      (searchString: string) => navigate(router.push, { pathname: currentPathname, searchString }),
      [router, currentPathname],
    ),
    replaceSearchString: useCallback(
      (searchString: string) => navigate(router.replace, { pathname: currentPathname, searchString }),
      [router, currentPathname],
    ),
  };
};
