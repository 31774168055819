import { ERoutePath } from '@/utils/ERoutePath';
import { getChainNameByChainId } from '@/utils/supportedChains';
import { OPTIONS_OF_TRENDING } from '../utils/optionsSelector';

export const NO_CATEGORY_DEFAULT_SLUG = 'tokens';

export const buildPath = (ids: { chainId: string | null; category: string | null }) => {
  const isDefaultChain = !ids.chainId || ids.chainId === OPTIONS_OF_TRENDING.defaultChainId;
  const isDefaultCategory = !ids.category || ids.category === OPTIONS_OF_TRENDING.defaultCategory;

  if (isDefaultChain && isDefaultCategory) {
    return ERoutePath.home;
  }

  const chainName =
    ids.chainId && ids.chainId !== OPTIONS_OF_TRENDING.defaultChainId ? getChainNameByChainId(ids.chainId) : '';

  const slugs = [
    '/top/trending',
    ids.category ? ids.category : NO_CATEGORY_DEFAULT_SLUG,
    chainName && chainName !== 'unknown' ? chainName : null,
  ].filter(Boolean);

  return slugs.join('/');
};
