import { useMemo } from 'react';

export const MiniLineChart = ({
  dataSet,
  width = 200,
  height = 60,
  strokeWidth = 2,
}: {
  dataSet: number[];
  width?: number;
  height?: number;
  strokeColor?: string;
  strokeWidth?: number;
}) => {
  const pointMul = 2;
  const pointSize = pointMul * strokeWidth;

  const points = useMemo(() => {
    const edgeFactor = pointSize;
    const max = dataSet.reduce((a, b) => Math.max(a, b));
    const min = dataSet.reduce((a, b) => Math.min(a, b));
    const diff = max - min;
    const xStep = (width - edgeFactor * 2) / (dataSet.length - 1);

    return dataSet.map((val, i) => {
      const x = edgeFactor + Math.round(i * xStep);
      const h = height - pointSize * 2;
      const y = h + pointSize - Math.round(((val - min) / diff) * h);
      return [x, y] as const;
    });
  }, [dataSet, width, height, strokeWidth]);

  // compose polyline points param
  const pointsStr = points.map((p) => p.join(',')).join(' ');

  return (
    <span style={{ display: 'inline-block', position: 'relative' }}>
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox={`0 0 ${width} ${height}`}
      >
        <polyline
          points={pointsStr}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        />
      </svg>
    </span>
  );
};
