import { isNullish } from '@/utils/isNullish';
import { numberConverter } from './numberConverter';

export const formatAsTokenAmount = (
  num: number,
  opts?: {
    maxDecimals?: number;
  },
): string => {
  const leadingZerosCount = numberConverter.getZerosAmountAfterPoint(num);
  const shouldBeSubscript = leadingZerosCount >= 2 && num < 1;

  if (shouldBeSubscript) {
    const roundedNumber = !isNullish(opts?.maxDecimals) ? Number(num.toPrecision(opts.maxDecimals)) : num;
    return numberConverter.convertToSubscript(roundedNumber, leadingZerosCount);
  }

  if (num < 1) {
    return num.toFixed(opts?.maxDecimals || 8).replace(/(?:\.0+|(\.\d+?)0+)$/, '$1');
  }

  if (num < 1000) {
    return num.toFixed(2).replace(/\.?0+$/, '');
  }

  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 1, // Allow 1 decimal place even for large numbers to avoid rounding errors
  }).format(num);
};
