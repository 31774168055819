import { Pill } from 'moralis-ui';
import { faChevronsDown, faChevronsUp, faEquals } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../TrendingPageUITableBody.module.scss';

const ICON_WIDTH = 12;
const ICON_HEIGHT = 12;

const rankChangeIcons = {
  positive: () => (
    <FontAwesomeIcon icon={faChevronsUp} width={ICON_WIDTH} height={ICON_HEIGHT} className={styles.positive} />
  ),
  equal: () => <FontAwesomeIcon icon={faEquals} width={ICON_WIDTH} height={ICON_HEIGHT} className={styles.equal} />,
  negative: () => (
    <FontAwesomeIcon icon={faChevronsDown} width={ICON_WIDTH} height={ICON_HEIGHT} className={styles.negative} />
  ),
};

export function RankChangeIcon({ rankChange }: { rankChange: number | null }) {
  if (rankChange == null) {
    return (
      <Pill color="blue" size="xs" variant="outline">
        <span className={styles.tokenInfoBoxRankNew}>new</span>
      </Pill>
    );
  } else if (rankChange > 0) {
    return rankChangeIcons.positive();
  } else if (rankChange === 0) {
    return rankChangeIcons.equal();
  } else {
    return rankChangeIcons.negative();
  }
}
