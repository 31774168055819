import { useSearchParams } from 'next/navigation';
import { logApp } from '@/utils/logApp';
import { SortOrder } from '@/utils/sorting';

type TableState<K extends string> = {
  column: K;
  order: SortOrder;
  pageIndex: number;
};
type QueryParam = string | null;
type QueryDict = Record<string, QueryParam>;

const log = logApp.create('useTableDataStateURL');

const convertStateToQueryDict = <K extends string>(state: TableState<K>): QueryDict => {
  return {
    column: state.column,
    order: state.order,
    page: state.pageIndex === 0 ? null : String(state.pageIndex + 1),
  };
};

const convertQueryDictToState = <K extends string>(
  searchParams: ReturnType<typeof useSearchParams>,
  fromQuery: (col: QueryParam) => K,
  { defaultSorting, pagesLength }: { defaultSorting: SortOrder; pagesLength: number },
): TableState<K> => {
  const raw = {
    column: searchParams.get('column'),
    order: searchParams.get('order'),
    page: searchParams.get('page'),
  };

  const pageNum = Number(raw.page) || 1; // handle NaN too
  const orderLower = String(raw.order).toLowerCase();

  return {
    column: fromQuery(raw.column),
    order: orderLower === 'asc' ? SortOrder.ASC : orderLower === 'desc' ? SortOrder.DESC : defaultSorting,
    pageIndex: pageNum < 1 || pageNum > pagesLength ? 0 : pageNum - 1,
  };
};

const updateSearchParams = (sp: URLSearchParams, query: QueryDict) => {
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (value !== null) {
      sp.set(key, value);
    } else {
      sp.delete(key);
    }
  });
  return sp;
};

const getSearchString = (searchParams: ReturnType<typeof useSearchParams>, dict: QueryDict) =>
  updateSearchParams(new URLSearchParams([...searchParams.entries()]), dict).toString();

/**
 * This hook is used to manage the state of a table with sorting and pagination
 * It's state is represented in the URL query params, so no state is stored in the component
 * Any extra unknown parameters are kept untouched
 *
 * @generic K - The type of the column names, e.g. an enum of valid column names
 */
export const useTableDataStateURL = <K extends string>({
  totalLength,
  fromQuery,
  itemsPerPage,
  defaultSorting = SortOrder.DESC,
}: {
  /**
   * The total length of the data, used to calculate the number of pages
   */
  totalLength: number;
  /**
   * Function to convert the query param to the column name
   * @returns The column name
   */
  fromQuery: (val: QueryParam) => K;
  /**
   * The number of items per page
   */
  itemsPerPage: number;
  /**
   * The default sorting order
   * @default SortOrder.DESC
   */
  defaultSorting?: SortOrder;
}) => {
  const pagesLength = Math.ceil(totalLength / itemsPerPage);
  const searchParams = useSearchParams();
  const queryParams = convertQueryDictToState(searchParams, fromQuery, { defaultSorting, pagesLength });

  const update = (qd: QueryDict) => {
    if (qd.column === fromQuery(null) && qd.order === defaultSorting) {
      qd.column = null;
      qd.order = null;
    }
    return getSearchString(searchParams, qd);
  };

  const defaultSearch = update({ column: null, order: null, page: null });

  const updateSorting = (column: K, order = defaultSorting) => {
    log.debug('updateSorting', { queryParams, totalLength });
    return update(convertStateToQueryDict({ column, order, pageIndex: 0 }));
  };

  return {
    tableSort: {
      column: queryParams.column,
      order: queryParams.order,
    },

    tablePage: {
      totalLength,
      pagesLength,
      itemsPerPage,
      currentIndex: queryParams.pageIndex,
    },

    /**
     * Update the current page index
     */
    updateCurrentPageIndex: (index: number) => {
      log.debug('updateCurrentPageIndex', { index, queryParams });
      if (index < 0 || index >= pagesLength) {
        index = 0;
      }
      return update(
        convertStateToQueryDict({
          column: queryParams.column,
          order: queryParams.order,
          pageIndex: index,
        }),
      );
    },

    /**
     * Update the sorting column and order. Reset the page index to 0
     * @param colName - The known column name
     * @param order - The sorting order
     */
    updateSorting,

    /**
     * Toggle the sorting order of the given column:
     * @param colName - The known column name
     * - if the column is already sorted, it will change the order
     * - If the column is different, the default order will be used
     */
    toggleSorting: (colName: K) => {
      log.debug('toggleSorting', { colName, queryParams });
      if (queryParams.column !== colName) {
        return updateSorting(colName);
      }
      const order = queryParams.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
      return updateSorting(colName, order);
    },

    /**
     * Reset the query params to the initial state: page index = 0, default sorting
     *
     * Optionally, you can provide a different path to navigate to
     */
    resetQuery: () => {
      log.debug('resetQuery', { queryParams, defaultSearch });
      return update({ column: null, order: null, page: null });
    },
  };
};
