/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { Skeleton, TableCell } from 'moralis-ui';
import styles from './TrendingTableLoadingSkeleton.module.scss';

const LOADER_ROWS = 3;
const LOADER_COLUMNS = 12;

export const TrendingTableLoadingSkeleton = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return Array.from({ length: LOADER_ROWS }).map((_, rowIndex) => (
    <tr key={rowIndex}>
      {Array.from({ length: LOADER_COLUMNS }).map((_, colIndex) => {
        return (
          <TableCell key={colIndex} align="right">
            <Skeleton type="rectangle" className={styles.skeleton} />
          </TableCell>
        );
      })}
    </tr>
  ));
};
