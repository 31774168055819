import React from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import clsx from 'clsx';
import { BatteryChart, TableCell, Tooltip } from 'moralis-ui';
import { CoinLogo } from '@/components/common/CoinLogo';
import { SafetyShield } from '@/components/common/SafetyShield';
import TokenMetric from '@/components/common/Tables/atoms/TokenMetric';
import { TrendingCoinInfoDto } from '@/services/models/TrendingCoinInfoDto';
import { Advertisement } from '@/utils/contentful/type';
import { formatMetric, formatTokenPrice } from '@/utils/fmt';
import { formatAsTokenAmount } from '@/utils/formatAsTokenAmount';
import { getTimestampDistanceInDays } from '@/utils/getTimestampDistance';
import { computeHypeScore } from '@/utils/hypeScore';
import { getChainNameByChainId } from '@/utils/supportedChains';
import { getCoinURLById } from '@/utils/urls';
import { DataSyncingInfo } from '../common/DataSyncingInfo/DataSyncingInfo';
import { getRandomTrendingTokenPageAd } from './utils/ad';
import { RankChangeIcon } from './utils/rankChangeIcon';
import { HomePageCategoryPills } from './HomePageCategoryPills';
import { TrendingTableLoading } from './TrendingTable';
import styles from './TrendingPageUITableBody.module.scss';

const TimeAgoInfo = dynamic(() => import('./TimeAgoInfo'), {
  ssr: false,
});

const TrendingPageAd = dynamic(() => import('./TrendingPageAd').then((mod) => mod.TrendingPageAd), {
  ssr: false,
});

export const TrendingPageUITableBody = ({
  trendingTokenPage,
  currentPage,
  itemsPerPage,
  showTokenRank,
  ads,
  isTrendingTokenDataLoading,
}: {
  currentPage: number;
  itemsPerPage: number;
  trendingTokenPage: TrendingCoinInfoDto[];
  showTokenRank: boolean;
  ads: Advertisement[];
  isTrendingTokenDataLoading?: boolean;
}) => {
  if (isTrendingTokenDataLoading) {
    return <TrendingTableLoading />;
  }

  const selectedAd = getRandomTrendingTokenPageAd({
    ads,
    rowCount: trendingTokenPage.length,
  });

  return (
    <>
      {trendingTokenPage.map((token, index) => {
        const rowNumber = (currentPage - 1) * itemsPerPage + index + 1;
        const chainName = getChainNameByChainId(token.chainId);
        const batteryChartValue = computeHypeScore(token.hypeScore || 0) / 100;
        let batteryChartValueShowUser = Math.round(batteryChartValue * 100);
        if (batteryChartValueShowUser === 100) {
          batteryChartValueShowUser -= 1;
        }

        return (
          <React.Fragment key={`wrap-${token.coinAddress}-${token.chainId}`}>
            {/* index + 1 makes sure the ad placement is respected */}
            {index + 1 === selectedAd?.placement && selectedAd?.ad && <TrendingPageAd ad={selectedAd?.ad} />}

            <tr key={`tr-${token.coinAddress}-${token.chainId}`}>
              <TableCell align="left" className={styles.fieldToken}>
                <Link
                  legacyBehavior
                  passHref
                  href={getCoinURLById(token)}
                  key={`link-${token.coinAddress}-${token.chainId}`}
                >
                  <a className={styles.tokenInfo}>
                    <span className={clsx('caption-14-regular', styles.tokenInfoRowNumber)}>{rowNumber}</span>
                    <span className={styles.tokenInfoBox}>
                      <span className={styles.tokenInfoBoxLogo}>
                        <CoinLogo
                          chainId={token.chainId}
                          name={token.name || token.symbol || 'N/A'}
                          size="small"
                          src={token.imgURL}
                        />
                      </span>
                      <span className={styles.tokenInfoBoxTitle}>
                        <span
                          className={clsx(
                            styles.tokenInfoBoxTitleName,
                            styles.tokenInfoBoxTitleNameDesktop,
                            'body-16-medium',
                          )}
                        >
                          {token.name ?? 'N/A'}
                        </span>
                        <span
                          className={clsx(
                            styles.tokenInfoBoxTitleName,
                            styles.tokenInfoBoxTitleNameMobile,
                            'body-16-medium',
                          )}
                        >
                          {token.symbol ?? 'N/A'}
                        </span>
                        <span className={clsx('caption-12-regular', styles.tokenInfoBoxTitleSymbol)}>{chainName}</span>
                      </span>
                    </span>
                  </a>
                </Link>
              </TableCell>

              {showTokenRank && (
                <TableCell align="left" className={styles.fieldTokenRank}>
                  <Tooltip
                    // Quick temporary fix added. TODO: Remove when the new stable layout is implemented.
                    style={{ zIndex: '30' }}
                    color="navy"
                    content="Change in token position based on Hype Score over the last 10 minutes"
                    position="top"
                    size="md"
                    trigger={
                      <span className={styles.tokenInfoBoxRank}>
                        <span className={styles.tokenInfoBoxRankIcon}>
                          <RankChangeIcon rankChange={token.rankChange} />
                        </span>
                        {token.rankChange != null && (
                          <span className={styles.tokenInfoBoxRankNumber}>{Math.abs(token.rankChange)}</span>
                        )}
                      </span>
                    }
                  />
                </TableCell>
              )}

              <TableCell align="left" className={styles.fieldTable}>
                <span className={clsx(styles.tokenInfoBoxValue, styles.tokenInfoBatteryValue)}>
                  <BatteryChart
                    stops={[
                      [0, '#7EC393'],
                      [0.5, '#4CE666'],
                    ]}
                    value={batteryChartValue}
                  />
                  <span className={clsx('caption-14-medium', styles.batteryChartValueShowUser)}>
                    {batteryChartValueShowUser}%
                  </span>
                </span>
              </TableCell>

              <TableCell align="left" className={styles.fieldTable}>
                <span
                  className={clsx('body-16-medium', styles.tokenInfoBoxValue, styles.equal, {
                    [styles.positive]:
                      token.firstSeenMaxPricePercentageChange !== null && token.firstSeenMaxPricePercentageChange > 0,
                    [styles.negative]:
                      token.firstSeenMaxPricePercentageChange !== null && token.firstSeenMaxPricePercentageChange < 0,
                  })}
                >
                  {token.firstSeenMaxPricePercentageChange !== null
                    ? token.firstSeenMaxPricePercentageChange === 0
                      ? '0%'
                      : formatMetric(token.firstSeenMaxPricePercentageChange, 'percent', true).value
                    : 'N/A'}
                </span>
                <TimeAgoInfo timestampInSeconds={token.firstSeenTimestampInSeconds ?? null} />
              </TableCell>

              <TableCell align="left" className={styles.fieldTable}>
                <span
                  className={clsx('body-16-medium', styles.tokenInfoBoxValue, styles.equal, {
                    [styles.positive]: token.price24HChange !== null && token.price24HChange > 0,
                    [styles.negative]: token.price24HChange !== null && token.price24HChange < 0,
                  })}
                >
                  {token.price24HChange !== null ? formatMetric(token.price24HChange, 'percent', true).value : 'N/A'}
                </span>
                <span className={clsx('caption-12-medium', styles.tokenInfoBoxValue)}>
                  {formatTokenPrice(token.price !== null ? token.price : undefined)}
                </span>
              </TableCell>
              <TableCell align="right" className={styles.fieldTable}>
                <span className={clsx('body-16-medium', styles.tokenInfoBoxValue)}>
                  $
                  {formatAsTokenAmount(
                    token.fullyDilutedValuation !== null ? parseFloat(token.fullyDilutedValuation.toString()) : 0,
                  )}
                </span>
              </TableCell>
              <TableCell align="right" className={styles.fieldTable}>
                {token.blockTimestamp ? (
                  <span className={clsx('body-16-medium', styles.tokenInfoBoxValue)}>
                    {getTimestampDistanceInDays(token.blockTimestamp)}
                  </span>
                ) : (
                  <DataSyncingInfo />
                )}
              </TableCell>
              <TableCell align="right" className={styles.fieldTable}>
                <TokenMetric
                  className={clsx('body-16-medium', styles.tokenInfoBoxValue, styles.tokenInfoBoxValueVolume)}
                  currency={true}
                  hasTooltip={false}
                  value={token.volume24H || 0}
                />
              </TableCell>
              <TableCell align="right" className={styles.fieldTable}>
                <TokenMetric
                  className={clsx('body-16-medium', styles.tokenInfoBoxValue)}
                  currency={true}
                  hasChangeIndication={true}
                  hasTooltip={false}
                  value={token.buyPressure || 0}
                />
              </TableCell>
              <TableCell align="right" className={styles.fieldTable}>
                <span className={clsx('body-16-medium', styles.tokenInfoBoxValue)}>
                  {token.twitterFollowerCount !== null && (
                    <>{new Intl.NumberFormat('en-US').format(token.twitterFollowerCount || 0)}</>
                  )}
                </span>
              </TableCell>
              <TableCell align="right" className={styles.fieldTable}>
                <HomePageCategoryPills categories={token.categories} />
              </TableCell>
              <TableCell align="center" className={styles.fieldScore}>
                <span className={styles.fieldScoreBox}>
                  <SafetyShield dexLevel={token.moralisScore ?? 0} fontSize={12} shieldSize={32} />
                </span>
              </TableCell>
            </tr>
          </React.Fragment>
        );
      })}
    </>
  );
};
