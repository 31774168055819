import { GetServerSidePropsContext } from 'next';
import { TrendingCoinInfoDto } from '@/services';
import { fetchJSON } from '@/utils/api/fetchJSON';
import { getAPIByRequest } from '@/utils/nextjs/api';

export const fetchTopTrendingTokensFromAPI = async (context: GetServerSidePropsContext) => {
  const { apiServer } = getAPIByRequest(context.req);
  const tokens = await apiServer.coins.coinsControllerGetTrendingCoins();

  return {
    tokens,
  };
};

export const fetchTrendingTokens = async (slug: string = '') => {
  const baseUrl = '/api/trending-tokens';
  const url = slug ? `${baseUrl}/${slug}` : baseUrl;
  const { coins } = await fetchJSON({
    url,
    response: fetchJSON.as<{
      coins: TrendingCoinInfoDto[];
      title: string;
      description: string;
    }>,
  });

  return coins;
};
