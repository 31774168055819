import { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { Badge, ButtonSecondary } from 'moralis-ui';
import { CategoriesLinkList } from '@/components/common/HomePage/CategoriesLinkList';
import { ChainsLinkList } from '@/components/common/HomePage/HomePageChains';
import { HomePageTabs } from '@/components/common/HomePage/HomePageTabs';
import { EHomePageView } from '@/components/common/HomePage/lib/EHomePageView.enum';
import { buildPath } from '@/components/HomePageTrending/utils/buildPath';
import { DataSummaryVolume } from '@/components/HomePageTrending/utils/fetchChainsDataSummary';
import { ChainDataSummaryDto } from '@/services/models/ChainDataSummaryDto';
import { TrendingCoinInfoDto } from '@/services/models/TrendingCoinInfoDto';
import { useDrawerStore } from '@/stores/drawerStore';
import { Advertisement, TGlossaryItem, TTokenStrategiesDataResponsePartial } from '@/utils/contentful/type';
import { SortOrder } from '@/utils/sorting';
import { CHAIN_ID_LIST, EXCLUDED_FROM_TRENDING_CHAIN_IDS } from '@/utils/supportedChains';
import { faSliders } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortedCategoriesProps } from './utils/getSortedCategoriesByVolume';
import { createCategoryOptions, createChainIDOptions, OPTIONS_OF_TRENDING } from './utils/optionsSelector';
import { ChainsDataSummary } from './ChainsDataSummary';
import { TrendingPageUIEmpty } from './TrendingPageUIEmpty';
import { TrendingPageUIFilters } from './TrendingPageUIFilters';
import { TrendingTable } from './TrendingTable';
import { SortableColumnsTrending } from './TrendingToken';
import styles from './TrendingPageUI.module.scss';

const UNCATEGORIZED_CATEGORY_POSITION = 5;

export const TrendingPageUI = ({
  ads,
  category,
  categoryTitle,
  chainId,
  chainTitle,
  contentfulTokenDataStrategies,
  currentPage,
  dataSummaryNetVolume,
  dataSummaryTokens,
  dataSummaryTotalVolume,
  glossaryMoneyMetricTerms,
  hasMoreData,
  itemsPerPage,
  selectedFiltersCount,
  showTokenRank,
  sortedCategories,
  sortingColumn,
  sortingOrder,
  tabId,
  trendingTokenPage,
  isTrendingTokenDataLoading,
  onChangeCategory,
  onChangeChainId,
  onChangePageNumber,
  onSortChange,
}: {
  ads: Advertisement[];
  category: string | null;
  categoryTitle?: string;
  chainId: string | null;
  chainTitle?: string;
  contentfulTokenDataStrategies?: TTokenStrategiesDataResponsePartial[];
  currentPage: number;
  dataSummaryNetVolume: DataSummaryVolume[];
  dataSummaryTokens: ChainDataSummaryDto;
  dataSummaryTotalVolume: DataSummaryVolume[];
  glossaryMoneyMetricTerms: TGlossaryItem[];
  hasMoreData: boolean;
  itemsPerPage: number;
  selectedFiltersCount: number;
  showTokenRank: boolean;
  sortedCategories: SortedCategoriesProps;
  sortingColumn: SortableColumnsTrending;
  sortingOrder: SortOrder;
  tabId: EHomePageView;
  isTrendingTokenDataLoading?: boolean;
  trendingTokenPage: TrendingCoinInfoDto[];
  onChangeCategory: (category: string | null) => void;
  onChangeChainId: (chainId: string | null) => void;
  onChangePageNumber: () => void;
  onSortChange: (column: SortableColumnsTrending) => void;
}) => {
  const { showDrawer, hideDrawer, setDrawerContent } = useDrawerStore();

  const opt = {
    allCategories: createCategoryOptions(sortedCategories),
    category: category ?? OPTIONS_OF_TRENDING.defaultCategory,
    changeCategory: useCallback((cat: string) => {
      onChangeCategory(cat === OPTIONS_OF_TRENDING.defaultCategory ? null : cat);
      hideDrawer();
    }, []),

    allChainIds: createChainIDOptions(
      Object.values(CHAIN_ID_LIST).filter((chainId) => !EXCLUDED_FROM_TRENDING_CHAIN_IDS.includes(chainId)),
    ),
    chainId: chainId ?? OPTIONS_OF_TRENDING.defaultChainId,
    changeChainId: useCallback((id: string) => {
      onChangeChainId(id === OPTIONS_OF_TRENDING.defaultChainId ? null : id);
      hideDrawer();
    }, []),
  };

  // Place the "Uncategorized" category first in the dropdown list
  const sortedCategoriesUncategorized = useMemo(() => {
    const categories = [...opt.allCategories];
    const uncategorizedCategory = categories.find((category) => category.id === OPTIONS_OF_TRENDING.uncategorized);
    if (uncategorizedCategory) {
      categories.splice(categories.indexOf(uncategorizedCategory), 1);
      categories.splice(UNCATEGORIZED_CATEGORY_POSITION, 0, uncategorizedCategory);
    }
    return categories;
  }, [opt.allCategories]);

  useEffect(() => {
    setDrawerContent(
      <TrendingPageUIFilters
        categoryOptions={opt.allCategories}
        onChangeCategory={opt.changeCategory}
        category={opt.category}
        chainIdOptions={opt.allChainIds}
        onChangeChainId={opt.changeChainId}
        chainId={opt.chainId}
        onCloseFilters={hideDrawer}
      />,
    );
  }, [setDrawerContent, onChangeCategory, onChangeChainId, category, chainId, hideDrawer]);

  const generateTrendingHref = useCallback(
    (chainId: string) => {
      return buildPath({ chainId, category: opt.category });
    },
    [opt.category],
  );

  return (
    <>
      <div className={styles.mainPage}>
        <ChainsLinkList
          options={opt.allChainIds}
          value={opt.chainId}
          onChainChange={opt.changeChainId}
          generateHref={generateTrendingHref}
        />

        {category !== OPTIONS_OF_TRENDING.uncategorized && (
          <ChainsDataSummary
            dataSummaryTokens={dataSummaryTokens}
            dataSummaryTotalVolume={dataSummaryTotalVolume}
            dataSummaryNetVolume={dataSummaryNetVolume}
            chainId={chainId}
            category={category === OPTIONS_OF_TRENDING.defaultCategory ? null : category}
          />
        )}

        <div className={styles.header}>
          <HomePageTabs tabId={tabId} />

          <CategoriesLinkList
            options={sortedCategoriesUncategorized}
            value={opt.chainId}
            currentCategory={opt.category}
            onChangeCategory={(categoryId) => opt.changeCategory(categoryId)}
          />
        </div>
        <div className={styles.pageInfoTable}>
          <div className={styles.tokensTable}>
            {trendingTokenPage.length > 0 || isTrendingTokenDataLoading ? (
              <TrendingTable
                hasMoreData={hasMoreData}
                ads={ads}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                rows={trendingTokenPage}
                sortingColumn={sortingColumn}
                sortingOrder={sortingOrder}
                onSortChange={onSortChange}
                glossaryMoneyMetricTerms={glossaryMoneyMetricTerms}
                showTokenRank={showTokenRank}
                onChangePageNumber={onChangePageNumber}
                isTrendingTokenDataLoading={isTrendingTokenDataLoading}
              />
            ) : (
              <TrendingPageUIEmpty
                onShowFilters={showDrawer}
                categoryTitle={categoryTitle}
                chainTitle={chainTitle}
                contentfulTokenDataStrategies={contentfulTokenDataStrategies}
                chainId={chainId}
                category={category === OPTIONS_OF_TRENDING.defaultCategory ? null : category}
                tokensCount={dataSummaryTokens.tokensCount}
              />
            )}
          </div>
        </div>
      </div>

      <div
        className={clsx(
          styles.tokenTableFiltersButton,
          selectedFiltersCount > 0 && styles.tokenTableFiltersButtonValues,
        )}
      >
        <ButtonSecondary aria-label="tableFilters" size="md" onClick={showDrawer}>
          <FontAwesomeIcon icon={faSliders} />
          Filters
          {selectedFiltersCount > 0 && (
            <span className={styles.tokenTableFiltersCount}>
              <Badge color="navy" id="tokenTableFiltersCount" size="md" text={selectedFiltersCount} />
            </span>
          )}
        </ButtonSecondary>
      </div>
    </>
  );
};
