/**
 * @abstract Handles the ad logic for the HomePageTrending component
 *
 * - Ad must contain "Home Trending Token Table" in placements array
 * - Ad should be randomly placed in the table between the 4th and 10th row
 * - Ad should NOT be placed in the table if the table has less than 4 rows
 * - Ad should be placed in all trending pages, not just on the 1st one
 */

import { random, sample } from 'lodash';
import { Nullish } from '@/types/common';
import { Advertisement } from '@/utils/contentful/type';

const MIN_NUM_ROWS = 4;

const MIN_POS = 4;
const MAX_POS = 10;

export const getRandomTrendingTokenPageAd = (args: {
  ads: Advertisement[];
  rowCount: number;
}): Nullish<{
  ad: Advertisement;
  placement: number;
}> => {
  if (!args.ads.length || args.rowCount < MIN_NUM_ROWS) {
    return null;
  }

  // Filter for specific ads and select a random one
  const homeTrendingTokenTableAds = getHomeTrendingTokenTableAds(args.ads);
  const randomAd = sample(homeTrendingTokenTableAds);

  // Return null if no suitable ad is found or if no valid placement can be determined
  if (!randomAd) {
    return null;
  }

  const placement = generateRandomPlacementIndex(args.rowCount);
  if (!placement) {
    return null;
  }

  return {
    ad: randomAd,
    placement,
  };
};

const getHomeTrendingTokenTableAds = (ads: Advertisement[]) =>
  ads.filter((ad) => ad.placements.includes('Home Trending Tokens Table'));

const generateRandomPlacementIndex = (rowCount: number): number | null => {
  if (rowCount < MIN_NUM_ROWS) {
    return null;
  }

  const max = Math.min(rowCount, MAX_POS);

  return random(MIN_POS, max);
};
