import { clsx } from 'clsx';
import { Card, IconBackground } from 'moralis-ui';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TokenMetric from '../common/Tables/atoms/TokenMetric';
import { MiniLineChart } from './MiniLineChart';
import styles from './InfoBox.module.scss';

const numF = new Intl.NumberFormat();
const Item = ({
  after,
  icon,
  title,
  children,
}: React.PropsWithChildren<{
  icon?: React.ReactNode;
  after?: React.ReactNode;
  title: string;
}>) => {
  return (
    <Card size="md" transparent className={clsx(styles.infoBox, after && styles.infoBoxDoubleSize)}>
      {icon && (
        <div className={styles.infoBoxIcon}>
          <IconBackground icon={icon} />
        </div>
      )}

      <div className={styles.infoBoxData}>
        <div className={styles.infoBoxTitle}>{title}</div>
        {children}
      </div>

      {after}
    </Card>
  );
};

export const InfoBoxCount = ({ count }: { count: number }) => (
  <div className={styles.infoBoxNumber}>{numF.format(count)}</div>
);

export const InfoBoxMoney = ({ money }: { money: number }) => (
  <TokenMetric className={styles.infoBoxNumber} currency={true} hasTooltip={false} value={money} />
);

export const InfoBoxLink = ({ children }: React.PropsWithChildren) => (
  <span className={styles.infoBoxLink}>
    {children} <FontAwesomeIcon icon={faArrowRight} />
  </span>
);

const InfoBoxGraph = ({ children, diff }: React.PropsWithChildren<{ diff: number }>) => (
  <div
    className={clsx(styles.infoBoxGraph, {
      [styles.infoBoxGraphPositive]: diff > 0,
      [styles.infoBoxGraphNegative]: diff < 0,
    })}
  >
    {children}
  </div>
);

export const InfoBoxWrapper = ({ children }: React.PropsWithChildren) => (
  <div className={styles.infoBoxWrapper}>{children}</div>
);

export const InfoBoxWithCount = ({
  icon,
  title,
  count,
  children,
}: React.PropsWithChildren<{
  title: string;
  count: string | undefined;
  icon?: React.ReactNode;
}>) => (
  <Item icon={icon} title={title}>
    {count != null ? <InfoBoxCount count={parseInt(count, 10)} /> : <div className={styles.infoBoxNumberNone}>N/A</div>}
    {children}
  </Item>
);

export const InfoBoxWithMoney = ({
  title,
  money,
  graphData,
  children,
}: React.PropsWithChildren<{
  title: string;
  money: string | undefined;
  graphData: number[] | null;
}>) => (
  <Item
    title={title}
    after={
      graphData && (
        //I replaced the last value in the array with the current {money} value to normalize the chart data.
        // In a month, we'll update the data retrieval logic for the chart.
        <InfoBoxGraph diff={parseFloat((money ?? graphData[graphData.length - 1]).toString()) - graphData[0]}>
          <MiniLineChart
            dataSet={
              money !== undefined
                ? graphData.map((value, index) => (index === graphData.length - 1 ? parseFloat(money) : value))
                : graphData
            }
          />
        </InfoBoxGraph>
      )
    }
  >
    {money != null ? <InfoBoxMoney money={parseFloat(money)} /> : <div className={styles.infoBoxNumberNone}>N/A</div>}
    {children}
  </Item>
);
