import React, { useMemo } from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { ButtonLink, Dropdown } from 'moralis-ui';
import { buildPath } from '@/components/HomePageTrending/utils/buildPath';
import remCalc from '@/helpers/utils/remCalc';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CategoriesLinkList.module.scss';

const MAX_DISPLAYED_CATEGORIES_DEFAULT = 5;

export const CategoriesLinkList = ({
  options,
  value,
  currentCategory,
  onChangeCategory,
}: {
  options: { id: string; label: string }[];
  value: string;
  currentCategory: string;
  onChangeCategory: (categoryId: string) => void;
}) => {
  const showFirstCategories = useMemo(() => options.slice(0, MAX_DISPLAYED_CATEGORIES_DEFAULT), [options]);
  const showRemainingCategories = useMemo(() => options.slice(MAX_DISPLAYED_CATEGORIES_DEFAULT), [options]);

  const isShowRemainingCategoriesActive = useMemo(
    () => showRemainingCategories.some((cat) => cat.id === currentCategory),
    [showRemainingCategories, currentCategory],
  );

  return (
    <div className={styles.categoriesList}>
      {showFirstCategories.map((cat) => {
        const href = buildPath({ chainId: value, category: cat.id });
        return (
          <Link
            key={cat.id}
            href={href}
            className={clsx(styles.link, { [styles.active]: currentCategory === cat.id })}
            // ToDo: remove the implementation of link generation and navigation through state
            // A temporary solution due to functionality inconsistency in two places
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) {
                return;
              }
              e.preventDefault();
              onChangeCategory(cat.id);
            }}
          >
            {cat.label}
          </Link>
        );
      })}
      {showRemainingCategories.length > 0 && (
        <Dropdown
          position="bottom"
          size="sm"
          trigger={
            <ButtonLink size="xs" className={clsx(styles.link, { [styles.active]: isShowRemainingCategoriesActive })}>
              <span className={styles.linkText}>
                <FontAwesomeIcon icon={faEllipsis} style={{ width: remCalc('12px'), height: remCalc('12px') }} /> More
              </span>
            </ButtonLink>
          }
        >
          <div className={styles.categoriesDropdown}>
            {showRemainingCategories.map((cat) => {
              const href = buildPath({ chainId: value, category: cat.id });
              return (
                <Link
                  key={cat.id}
                  href={href}
                  className={clsx(styles.linkDropdown, { [styles.linkDropdownActive]: currentCategory === cat.id })}
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      return;
                    }
                    e.preventDefault();
                    onChangeCategory(cat.id);
                  }}
                >
                  {cat.label}
                </Link>
              );
            })}
          </div>
        </Dropdown>
      )}
    </div>
  );
};
