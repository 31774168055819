import clsx from 'clsx';
import { ButtonPrimary, Select } from 'moralis-ui';
import { TOperator, TOperatorPrefix } from '@/utils/tx/TTokenExplorerFilters';
import { faSliders } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TrendingPageUIFilters.module.scss';

export const TrendingPageUIFilters = ({
  categoryOptions,
  onChangeCategory,
  category,

  chainIdOptions,
  onChangeChainId,
  chainId,

  onCloseFilters,
}: {
  categoryOptions: TOperator[];
  onChangeCategory: (category: string) => void;
  category: string;

  chainIdOptions: TOperatorPrefix[];
  onChangeChainId: (chainId: string) => void;
  chainId: string;

  onCloseFilters: () => void;
}) => {
  return (
    <div className={styles.mobileFilters}>
      {categoryOptions.length > 0 || chainIdOptions.length > 0 ? (
        <div className={styles.mobileFiltersBox}>
          {chainIdOptions.length > 0 && (
            <>
              <div className={clsx(styles.mobileFiltersTitleChains, 'body-12-capitalize')}>Chains</div>
              <nav className={styles.mobileFiltersMenu}>
                <ul>
                  {chainIdOptions.map((chain) => {
                    return (
                      <li key={chain.id}>
                        <button
                          className={clsx(styles.mobileFiltersMenuLink, 'body-18-regular', {
                            [styles.isCurrent]: chain.id === chainId,
                          })}
                          type="button"
                          onClick={() => onChangeChainId(chain.id)}
                        >
                          <span className={styles.mobileFiltersMenuIcon}>{chain.prefix}</span>
                          <span className={styles.mobileFiltersMenuLabel}>{chain.label}</span>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </>
          )}

          {categoryOptions.length > 0 && (
            <div className={styles.headerSelectCategory}>
              <div className={clsx(styles.mobileFiltersTitleChains, 'body-12-capitalize')}>Category</div>
              <Select
                transparent
                id="mobileCategorySelect"
                name="mobileCategorySelect"
                options={categoryOptions}
                size="sm"
                value={category}
                onChange={(e) => onChangeCategory(e.target.value)}
              />
            </div>
          )}
        </div>
      ) : null}

      <ButtonPrimary className={styles.mobileFiltersCloseButton} size="md" onClick={onCloseFilters}>
        <FontAwesomeIcon icon={faSliders} />
        Apply Filters
      </ButtonPrimary>
    </div>
  );
};
