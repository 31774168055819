import { useCallback, useMemo, useReducer } from 'react';
import { logApp } from '@/utils/logApp';
import { buildPath } from './utils/buildPath';

const log = logApp.create('useTrendingFilters');
export type DefaultIDs = {
  defaultCategory: string | null;
  defaultChainId: string | null;
};

type State = ReturnType<typeof initState>;

type TheActions =
  | { type: 'setCategory'; category: string | null }
  | { type: 'setChainId'; chainId: string | null }
  | { type: 'reset' };
const reducer = (prevState: State, action: TheActions): State => {
  if (action.type === 'reset') {
    return {
      category: null,
      chainId: null,
    };
  }
  return {
    category: action.type === 'setCategory' ? action.category : prevState.category,
    chainId: action.type === 'setChainId' ? action.chainId : prevState.chainId,
  };
};

const initState = ({ defaultCategory, defaultChainId }: DefaultIDs) => {
  log.debug('initState', { defaultCategory, defaultChainId });
  return {
    category: defaultCategory,
    chainId: defaultChainId,
  };
};

export const useTrendingFilters = (defaults: DefaultIDs) => {
  const [state, dispatch] = useReducer(reducer, defaults, initState);
  const currentPath = useMemo((s = state) => buildPath(s), [state.category, state.chainId]);

  return {
    currentPath,
    reset: useCallback(() => {
      dispatch({ type: 'reset' });
    }, [dispatch]),

    category: state.category,
    updateCategory: useCallback(
      (category: string | null) => {
        dispatch({ type: 'setCategory', category });
      },
      [dispatch],
    ),

    chainId: state.chainId,
    updateChainId: useCallback(
      (chainId: string | null) => {
        dispatch({ type: 'setChainId', chainId });
      },
      [dispatch],
    ),

    filtersCount: [state.chainId, state.category].filter(Boolean).length,
  };
};
